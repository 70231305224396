import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, withModifiers as _withModifiers, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["name", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "qa-tab-radio flex row align-c",
    onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.selectNext && _ctx.selectNext(...args)), ["prevent"]))
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (opt, i) => {
      return (_openBlock(), _createElementBlock("label", {
        key: i,
        class: "qa-tr-label",
        ref_for: true,
        ref: _ctx.refs.labels
      }, [
        _withDirectives(_createElementVNode("input", {
          type: "radio",
          name: _ctx.name,
          value: opt.value,
          class: "qa-tr-radio",
          ref_for: true,
          ref: _ctx.refs.radios,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
          "data-focus-parent": "",
          onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent","stop"]))
        }, null, 8, _hoisted_1), [
          [_vModelRadio, _ctx.value]
        ]),
        _createElementVNode("span", null, _toDisplayString(opt.title || opt.value), 1)
      ]))
    }), 128)),
    _createElementVNode("div", {
      class: _normalizeClass(["qa-tr-select", { afterMount: _ctx.afterMount }]),
      style: _normalizeStyle(_ctx.selectStyle)
    }, null, 6)
  ]))
}