
import { IReference } from '@/api/types';
import { SessionCompleteVerse } from '@/store/modules/session/session.interfaces';
import { useStore } from '@/store/store';
import { referenceToString } from '@/utilities/utilityFunctions';
import { computed, ref } from 'vue';
import { defineComponent } from 'vue';
import TabRadio from '@/components/form/TabRadio.vue';

export default defineComponent({
  name: 'qa-verse-complete-graph',
  components: { TabRadio },
  setup() {
    const store = useStore();

    const showBox = ref(false);
    const showIncomplete = ref(true);
    const mousedIndex = ref(0);
    const ordering = ref<'verse' | 'quote'>('verse');
    const verses = computed(() => {
      const v = store.getters['session/limitedVerses']
        .map((verse, i) => {
          return {
            ...verse,
            index: i,
            ...(store.state.session.complete[i] as Partial<SessionCompleteVerse>),
          };
        })
        .filter((verse) => showIncomplete.value || !(!verse.reviewed && !verse.skipped));
      if (ordering.value === 'quote') {
        return v
          .map((verse) => ({
            ...verse,
            order:
              verse.order !== undefined
                ? verse.order
                : Object.keys(store.state.session.complete).length +
                  store.state.session.order.indexOf(verse.index),
          }))
          .sort((a, b) => a.order - b.order);
      }
      return v;
    });
    const contiguousRange = computed(() => {
      const i = verses.value.findIndex((v) => v.index === mousedIndex.value);
      let start = i;
      let end = i;
      while (start >= 0 && getState(verses.value[start]) === getState(verses.value[i])) {
        start--;
      }
      while (
        end < verses.value.length &&
        getState(verses.value[end]) === getState(verses.value[i])
      ) {
        end++;
      }
      return { start: ++start, end: --end, length: end - start + 1 };
    });
    const boxDim = computed(() => {
      const verseWidth = 100 / verses.value.length;
      const width = `${verseWidth * contiguousRange.value.length}%`;
      const left = `${verseWidth * contiguousRange.value.start}%`;
      const borderLeft = contiguousRange.value.start === 0;
      const borderRight = contiguousRange.value.end === verses.value.length - 1;
      const borderTopLeftRadius = borderLeft ? '8px' : '0';
      const borderBottomLeftRadius = borderTopLeftRadius;
      const borderTopRightRadius = borderRight ? '8px' : '0';
      const borderBottomRightRadius = borderTopRightRadius;
      return {
        width,
        left,
        borderBottomLeftRadius,
        borderTopLeftRadius,
        borderBottomRightRadius,
        borderTopRightRadius,
      };
    });
    const title = computed(() => {
      if (ordering.value === 'quote') {
        return '';
      }
      return (
        `${referenceToString(verses.value[contiguousRange.value.start])}` +
        (contiguousRange.value.length > 1
          ? `- ${referenceToString(verses.value[contiguousRange.value.end])}`
          : '')
      );
    });
    function getState(verse: Partial<SessionCompleteVerse> & IReference) {
      if (verse.reviewed && verse.correct) {
        return 'correct';
      } else if (verse.reviewed && !verse.correct && !verse.skipped) {
        return 'incorrect';
      } else if (verse.skipped) {
        return 'skipped';
      } else {
        return 'incomplete';
      }
    }
    function mouseIn(index: number) {
      mousedIndex.value = index;
      showBox.value = true;
    }
    function mouseOut() {
      showBox.value = false;
    }
    return {
      verses,
      reference: referenceToString,
      showIncomplete,
      mouseIn,
      mouseOut,
      showBox,
      boxDim,
      title,
      getState,
      ordering,
    };
  },
});
