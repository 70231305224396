
import { computed, defineComponent } from 'vue';
import { boundedNumber } from '@/utilities/utilityFunctions';
import Spinner from '@/components/atoms/Spinner.vue';
import PlaceholderParagraph from '@/components/structure/PlaceholderParagraph.vue';
import Aspect from '@/components/atoms/Aspect.vue';

export default defineComponent({
  name: 'QaQuizCard',
  components: {
    Spinner,
    PlaceholderParagraph,
    Aspect,
  },
  props: {
    verseText: {
      type: String,
      default: '',
    },
    refText: {
      type: String,
      default: '',
    },
    spinner: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const verseTextFontSize = computed(() => {
      const textLength = props.spinner ? 120 : props.verseText.length;
      let size = 16;
      let computedColumns = 180 / size;
      let computedRows = textLength / computedColumns;
      while (size * computedRows > 80) {
        size--;
        computedColumns = 180 / size;
        computedRows = textLength / computedColumns;
      }
      return boundedNumber(size, 6, 16);
    });
    return {
      verseTextFontSize,
    };
  },
});
