
import { computed, defineComponent } from 'vue';
import Toggle from '@/components/form/Toggle.vue';
import { useStore } from '@/store/store';
import QaButton from '@/components/form/Button.vue';
import { PATH } from '@/router/router';

export default defineComponent({
  name: 'SessionSettings',
  components: { Toggle: Toggle, QaButton },
  setup() {
    const store = useStore();
    const coachMode = computed({
      get: () => store.state.session.options.coachMode,
      set: (value: boolean) => store.commit('session/setCoachMode', value),
    });
    function openCardDisplay() {
      window.open(
        PATH.viewCard,
        '_blank',
        'location=yes,height=300,width=600,scrollbars=no,status=no'
      );
    }
    return {
      coachMode,
      openCardDisplay,
    };
  },
});
