
import { computed, defineComponent, PropType } from 'vue';
import CardStack from '@/components/atoms/CardStack.vue';
import { IReference } from '@/api/types';
import { referenceToString } from '@/utilities/utilityFunctions';

export default defineComponent({
  name: 'CardStackDisplay',
  components: { CardStack },
  props: {
    verses: {
      type: Array as PropType<IReference[]>,
      default: Array,
    },
  },
  setup(props) {
    const topCard = computed(() => {
      if (props.verses.length === 0) return '';
      return referenceToString(props.verses[0]);
    });

    return {
      topCard,
    };
  },
});
