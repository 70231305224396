import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "qa-card-stack" }
const _hoisted_2 = { class: "qa-cs-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuizCard = _resolveComponent("QuizCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createVNode(_component_QuizCard, { class: "qa-cs-card" }, null, 512), [
        [_vShow, _ctx.count > 2]
      ]),
      (_ctx.count > 1)
        ? (_openBlock(), _createBlock(_component_QuizCard, {
            key: 0,
            class: "qa-cs-card"
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_QuizCard, {
        class: "qa-cs-card qa-cs-card--top",
        style: _normalizeStyle({ opacity: _ctx.count > 0 ? 1 : 0 }),
        "ref-text": _ctx.visibleReference
      }, null, 8, ["style", "ref-text"])
    ])
  ]))
}