
import { defineComponent, ref, onMounted, onUnmounted, computed } from 'vue';

export default defineComponent({
  name: 'qa-aspect',
  props: {
    aspectRatio: {
      type: Number,
      default: 1,
    },
    initialWidth: {
      type: Number,
      default: -1,
    },
  },
  setup(props) {
    const rootEl = ref<HTMLElement>();
    const scale = ref(1);
    const implicitScale = ref(1);
    const style = computed(() => {
      return {
        width: `${100 * scale.value}%`,
        paddingBottom: `${(1 / props.aspectRatio) * 100 * scale.value}%`,
      };
    });
    function onResize() {
      const parentW = rootEl.value?.clientWidth;
      if (props.initialWidth > -1 && parentW !== undefined) {
        implicitScale.value = parentW / props.initialWidth;
      } else {
        implicitScale.value = 1;
      }
    }

    onMounted(() => {
      window.addEventListener('resize', onResize, true);
      onResize();
    });
    onUnmounted(() => {
      window.removeEventListener('resize', onResize);
    });
    return {
      rootEl,
      style,
      implicitScale,
    };
  },
});
