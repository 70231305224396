
import { generatePlaceholderText } from '@/utilities/utilityFunctions';
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'QaPlaceholderParagraph',
  props: {
    length: {
      type: Number,
      default: 0,
    },
    averageWordLength: {
      type: Number,
      default: 6,
    },
  },
  setup(props) {
    const placeholderText = computed(() => {
      return generatePlaceholderText(props.length, props.averageWordLength);
    });
    return {
      placeholderText,
    };
  },
});
