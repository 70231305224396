
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'QaToggle',
  props: {
    modelValue: {
      type: Boolean,
    },
    id: {
      type: String,
    },
  },
  emits: {
    'update:modelValue': null,
  },
  setup(props, { emit }) {
    const value = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    });

    return {
      value,
    };
  },
});
