import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "qa-aspect",
  ref: "rootEl"
}
const _hoisted_2 = { class: "qa-aspect-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "qa-aspect-size",
      style: _normalizeStyle(_ctx.style)
    }, [
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "default", { scale: _ctx.implicitScale })
      ])
    ], 4)
  ], 512))
}