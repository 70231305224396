
import { defineComponent } from 'vue';
import QuizCard from '@/components/atoms/QuizCard.vue';

export default defineComponent({
  name: 'QuoteSession',
  components: { QuizCard },
  props: {
    visibleReference: {
      type: String,
      default: '',
    },
    count: {
      type: Number,
      default: 0,
    },
  },
});
