
import { useStore } from '@/store/store';
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  PropType,
  ref,
  shallowRef,
  watch,
} from 'vue';
import IconButton from '@/components/molecules/IconButton.vue';
import RecordButton from '@/components/molecules/RecordButton.vue';
import { AudioRecorder } from '@/services/audio/audioRecorder';
import { AudioDb } from '@/storage/audio.db';
import AudioPlayer from './AudioPlayer.vue';
import { secondsToHuman } from '@/utilities/utilityFunctions';

export default defineComponent({
  name: 'SessionControls',
  components: { IconButton, RecordButton, AudioPlayer },
  emits: {
    recordStart: null,
    recordStop: null,
  },
  props: {
    mode: {
      type: String as PropType<'quote' | 'review'>,
    },
    currentIndex: {
      type: Number,
      default: -1,
    },
    state: {
      type: String as PropType<'play' | 'stop' | 'pause' | 'record' | ''>,
      default: '',
    },
  },
  setup(props, ctx) {
    const store = useStore();
    const shuffle = computed({
      get: () => store.state.session.shuffle,
      set: (value) => store.dispatch('session/shuffle', value),
    });

    const autoPlay = ref(false);

    const autoAdvance = computed(() => store.state.session.options.autoAdvance);

    const sessionId = computed(() => store.state.session.id);

    function skip(index: number) {
      store.dispatch('session/skipVerse', index);
    }

    const audioRecorder = shallowRef<AudioRecorder>();

    async function record() {
      try {
        if (!audioRecorder.value) {
          audioRecorder.value = new AudioRecorder();
        }
        await audioRecorder.value.start();
        ctx.emit('recordStart');
      } catch (e) {
        // Something failed with recording; perhaps an error modal?
      }
    }

    async function stop() {
      try {
        const result = await audioRecorder.value?.stop();
        ctx.emit('recordStop');
        return result;
      } catch (e) {
        // Something failed with stopping recording; uh-oh
      }
    }

    async function next() {
      const result = await stop();
      if (
        result &&
        result.blob &&
        props.currentIndex !== -1 &&
        props.currentIndex !== undefined
      ) {
        try {
          await AudioDb.addAudio({
            sessionId: sessionId.value,
            verseIndex: props.currentIndex,
            audio: {
              type: result.blob.type,
              data: await result.blob.arrayBuffer(),
            },
          });
          store.dispatch('session/quoteVerse', props.currentIndex);

          // If autoAdvance is true, start recording the next card
          if (autoAdvance.value) {
            record();
          }
        } catch (e) {
          // Some error storing audio
        }
      }
    }

    const isRecording = computed(() => !!audioRecorder.value?.isRecording.value);

    async function review(correct: boolean) {
      store.dispatch('session/reviewVerse', {
        verseIndex: props.currentIndex,
        correct,
      });

      autoPlay.value = autoAdvance.value;
    }

    const audioSrc = ref('');

    async function updateAudioSrc() {
      audioSrc.value = '';
      if (props.mode === 'review') {
        const sessionId = store.state.session.id;
        if (sessionId) {
          AudioDb.getAudio(sessionId, props.currentIndex).then((audioRecording) => {
            if (audioRecording) {
              const blob = new Blob([audioRecording.audio.data], {
                type: audioRecording.audio.type,
              });
              audioSrc.value = URL.createObjectURL(blob);
            }
          });
        }
      }
    }

    const speed = computed({
      get: () => store.state.session.options.playbackSpeed || 1,
      set: (speed: number) => store.commit('session/setPlaybackSpeed', speed),
    });

    const recordingLength = computed(() => {
      return secondsToHuman((audioRecorder.value?.lengthMs.value || 0) / 1000);
    });

    watch([() => props.currentIndex, () => props.mode], async () => {
      await updateAudioSrc();
    });

    onMounted(() => {
      AudioDb.initialize();
      updateAudioSrc();
    });

    watch(
      () => props.state,
      () => {
        if (props.mode === 'quote') {
          switch (props.state) {
            case 'stop':
              if (audioRecorder.value?.isRecording.value) {
                audioRecorder.value?.stop();
              }
              break;
            case 'record':
              if (audioRecorder.value && !audioRecorder.value.isRecording.value) {
                audioRecorder.value?.start();
              }
              break;
            default:
              break;
          }
        }
      }
    );

    onBeforeUnmount(() => {
      if (audioRecorder.value?.isRecording.value) {
        audioRecorder.value?.stop();
      }
    });

    const coachMode = computed(() => store.state.session.options.coachMode);
    const displayText = computed({
      get: () => store.state.session.displayText,
      set: (value: boolean) => store.commit('session/setDisplayText', value),
    });

    return {
      shuffle,
      skip,
      record,
      stop,
      next,
      audioRecorder,
      isRecording,
      audioSrc,
      review,
      autoPlay,
      speed,
      recordingLength,
      coachMode,
      displayText,
    };
  },
});
