import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "qa-card-stack-display" }
const _hoisted_2 = {
  key: 0,
  class: "qa-csd-count"
}
const _hoisted_3 = {
  key: 1,
  class: "qa-csd-name"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CardStack = _resolveComponent("CardStack")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.verses.length)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.verses.length), 1))
      : _createCommentVNode("", true),
    _createVNode(_component_CardStack, {
      class: "qa-csd-stack",
      "visible-reference": _ctx.topCard,
      count: _ctx.verses.length
    }, null, 8, ["visible-reference", "count"]),
    (_ctx.$slots.default)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "default")
        ]))
      : _createCommentVNode("", true)
  ]))
}