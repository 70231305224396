
import { useStore } from '@/store/store';
import { referenceToString } from '@/utilities/utilityFunctions';
import { computed } from 'vue';
import { defineComponent } from 'vue';
import Button from '@/components/form/Button.vue';
import VerseCompleteGraph from '@/components/molecules/VerseCompleteGraph.vue';
import { useRouter } from 'vue-router';
import { PATH } from '@/router/router';
import { IReference } from '@/api/types';

export default defineComponent({
  name: 'SessionComplete',
  props: {},
  setup(props, ctx) {
    const store = useStore();
    const router = useRouter();

    function endSession() {
      store.dispatch('ui/openOverlay', {
        type: 'modal',
        id: 'verify-end-session',
        title: 'End Session',
        body: 'Are you sure you want to end this session?',
        mainButton: 'Yes',
        secondaryButton: 'No',
        modalListeners: {
          primary: async () => {
            await store.dispatch('session/stopSession');
            ctx.emit('close');
            router.push(PATH.home);
          },
          secondary: () => {
            // Don't do anything
          },
        },
      });
    }

    const reviewedVerses = computed(() => {
      return store.getters['session/reviewedVerses'];
    });

    const correctVerses = computed(() => {
      return reviewedVerses.value.filter((verse) => {
        return store.state.session.complete[verse.index]?.correct;
      });
    });

    const incorrectVerses = computed(() => {
      return reviewedVerses.value.filter((verse) => {
        return (
          store.state.session.complete[verse.index] &&
          !store.state.session.complete[verse.index].correct
        );
      });
    });

    const incompleteVerses = computed(() => {
      return [
        ...store.getters['session/unquotedVerses'],
        ...store.getters['session/skippedVerses'],
      ];
    });

    function replace(verse: IReference & { index: number }) {
      store.dispatch('session/unquoteVerse', verse.index);
    }

    function replaceAll(verses: Array<IReference & { index: number }>) {
      verses.forEach(replace);
    }

    return {
      endSession,
      correctVerses,
      incorrectVerses,
      incompleteVerses,
      reference: referenceToString,
      replace,
      replaceAll,
    };
  },
  components: { Button, VerseCompleteGraph },
});
